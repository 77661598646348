/* Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

@media screen and (min-width: 1024px){
    #footerCsCenter {
        min-width: 550px;
        width: 590px;
    }
}